import axios from "axios";
import { openDB } from "idb";
import { modifyFiles } from "../../helpers/uploadFileUtil";
import {
  CHAT_USER,
  ACTIVE_USER,
  FULL_USER,
  ADD_ROOM,
  SET_CONTACTS,
  SET_ENTITIES,
  DELETE_ENTITY,
  ADD_CONTACT,
  BLOCK_CONTACT,
  UNBLOCK_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  UPDATE_GUEST,
  SET_GUESTS,
  ADD_GUEST,
  DELETE_GUEST,
  START_CHAT,
  SET_RECIPIENT,
  SET_USER_ONLINE,
  SET_USER_AWAY,
  SET_USER_OFFLINE,
  SET_NEW_MESSAGE,
  SET_NEW_MULTI_MESSAGE,
  SET_CHAT_ROOM,
  SET_CHAT_SEARCH,
  SET_ROOM_PROPERTIES,
  SET_WIDGET_PROPERTIES,
  UPDATE_USER,
  UPDATE_UNREAD,
  REMOVE_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE_CONVERSATION,
  DELIVERED_MESSAGE,
  SET_MESSAGES,
  SET_FILES,
  CLEAR_FILES,
  SET_UPLOAD_PROGRESS,
  SUCCESS_UPLOAD_FILE,
  FAILURE_UPLOAD_FILE,
  REMOVE_FROM_QUEUE,
  RETRY_UPLOAD_FILE,
  SET_REPLY_MESSAGE,
  READ_MESSAGE,
  ROOM_OPENED,
} from "./constants";

const INIT_STATE = {
  active_user: null,
  recipient: null,
  chatroom: null,
  rmid: null,
  users: [],
  guests: [],
  contacts: [],
  entities: [],
  groups: [],
  files: {},
  retry_upload: null,
  chatSearch: "",
};

const updateIndexedDB = async (chatroomId, messages) => {
  const db = await openDB(chatroomId, 1);
  const tx = db.transaction("messages", "readwrite");
  const store = tx.objectStore("messages");

  for (const message of messages) {
    await store.put(message);
  }

  await tx.done;
  db.close();
};

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHAT_USER:
      return {
        ...state,
      };

    case ACTIVE_USER:
      return {
        ...state,
        active_user: action.payload,
      };

    case SET_ROOM_PROPERTIES:
      var updatedProperties = state.users.map((user) => {
        var userProps = {
          ...user,
        };

        if (user) {
          var updatePropPayload = null;
          if (
            user.uuid === action.payload.uuid ||
            user.userid === action.payload._id ||
            user.recipient === action.payload._id
          ) {
            updatePropPayload = action.payload;
            userProps = {
              ...userProps,
              ...updatePropPayload,
            };
          }
        }
        return userProps;
      });

      return {
        ...state,
        users: updatedProperties,
      };

    case ADD_ROOM:
      let newRoom = [action.payload];
      var previousRooms = state.users.find(
        (user) =>
          user.uuid === newRoom[0].uuid ||
          user.userid === newRoom[0].userid ||
          user.userid === newRoom[0]._id ||
          user.recipient === newRoom[0]._id
      );
      if (previousRooms) {
        return {
          ...state,
        };
      } else {
        const initIndexedDB = async () => {
          const db = await openDB(newRoom[0].uuid, 1, {
            upgrade(db) {
              db.createObjectStore("messages", { keyPath: "_id" });
            },
          });
          db.close();
        };
        initIndexedDB();

        return {
          ...state,
          users: [...state.users, ...newRoom],
        };
      }

    case SET_RECIPIENT:
      return {
        ...state,
        recipient: action.payload,
      };

    case SET_REPLY_MESSAGE:
      return {
        ...state,
        rmid: action.payload,
      };

    case SET_CHAT_ROOM:
      // TODO: THIS IS NEEDED SOMEWHERE ELSE
      // var usersList = state.users.slice();
      // for (var room = 0; room < usersList.length; room++) {
      //   if (usersList[room].uuid === action.payload) {
      //     return {
      //       ...state
      //     };
      //   }
      // }
      return {
        ...state,
        chatroom: action.payload,
      };

    case SET_CHAT_SEARCH:
      return {
        ...state,
        chatSearch: action.payload,
      };

    case CLEAR_FILES:
      return {
        ...state,
        files: {},
      };

    case SET_FILES:
      return {
        ...state,
        files: {
          ...state.files,
          ...modifyFiles(state.files, action.payload),
        },
      };

    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.id]: {
            ...state.files[action.payload.id],
            progress: action.payload.progress,
          },
        },
      };

    case SUCCESS_UPLOAD_FILE:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload]: {
            ...state.files[action.payload],
            status: 1,
          },
        },
        retry_upload:
          state.retry_upload === action.payload ? null : state.retry_upload,
      };

    case FAILURE_UPLOAD_FILE:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload]: {
            ...state.files[action.payload],
            status: 99,
            progress: 0,
          },
        },
        retry_upload:
          state.retry_upload === action.payload ? null : state.retry_upload,
        // retry_upload: state.retry_upload.filter(index => index !== action.payload)
      };

    case RETRY_UPLOAD_FILE:
      const CancelToken = axios.CancelToken;
      const cancelSource = CancelToken.source();

      return {
        ...state,
        files: {
          ...state.files,
          [action.payload]: {
            ...state.files[action.payload],
            status: 2,
            progress: 0,
            cancelSource,
          },
        },
        retry_upload: action.payload,
        // retry_upload: [...state.retry_upload, action.payload]
      };

    case UPDATE_GUEST:
      let guestPayload = action.payload;
      var updatedGuestList = state.guests.map((guest) => {
        var currentGuest = { ...guest };
        if (guest.userId === guestPayload.userId) {
          currentGuest = { ...currentGuest, ...guestPayload };
        }
        return currentGuest;
      });

      var updatedUsersList = state.users.map((user) => {
        var currentUser = { ...user };
        if (user.userid === guestPayload.userId) {
          currentUser = { ...currentUser, ...guestPayload };
        }
        return currentUser;
      });

      return {
        ...state,
        guests: updatedGuestList,
        users: updatedUsersList,
      };

    case SET_GUESTS:
      return {
        ...state,
        guests: action.payload,
      };

    case ADD_GUEST:
      if (!action.payload || !action.payload.userId) {
        return state;
      }

      const { userId } = action.payload;
      const existingGuest = state.guests.find(
        (guest) => guest.userId === userId
      );

      if (existingGuest) {
        return state;
      }

      return {
        ...state,
        guests: [...state.guests, action.payload],
      };

    case DELETE_GUEST:
      if (!action.payload) {
        return state;
      }

      const updatedGuests = state.guests.map((guest) => {
        if (guest.userId === action.payload?.userId) {
          return {
            ...guest,
            isEngaged: true,
          };
        }
        return guest;
      });

      return {
        ...state,
        guests: updatedGuests,
      };

    case FULL_USER:
      return {
        ...state,
        users: action.payload,
      };

    case SET_MESSAGES:
      var bulkUpdateUsers = state.users.map((user) => {
        var msgUser = {
          ...user,
        };
        if (user.uuid === action.payload.chatroomId) {
          msgUser.messages = action.payload.messages;
        }
        return msgUser;
      });

      return {
        ...state,
        users: bulkUpdateUsers,
      };

    case UPDATE_CONVERSATION:
      var bulkUpdateConversation = state.users.map((user) => {
        var conversationUser = {
          ...user,
        };
        if (user.uuid === action.payload.chatroomId) {
          if (
            conversationUser.messages !== action.payload.conversation.length
          ) {
            conversationUser.messages = action.payload.conversation;
          }
        }
        return conversationUser;
      });

      return {
        ...state,
        users: bulkUpdateConversation,
      };

    case UPDATE_CONTACT:
      var updatedContacts = state.contacts.map((contact) => {
        var updateContUser = {
          ...contact,
        };

        if (contact) {
          var tempContact = null;
          var updateContactPayload = null;
          if (contact._id === action.payload._id) {
            updateContactPayload = action.payload;
            updateContUser = {
              ...updateContUser,
              ...updateContactPayload,
            };
          }
        }
        return updateContUser;
      });

      return {
        ...state,
        contacts: updatedContacts,
      };

    case UPDATE_MESSAGE:
      const updatedUsers = state.users.map((user) => {
        if (action.payload.chatroom && user.uuid !== action.payload.chatroom) {
          return user;
        }

        const updateMsgUser = { ...user };

        if (user.messages) {
          updateMsgUser.messages = user.messages.map((message) => {
            if (
              message._id === action.payload._id ||
              message.mid === action.payload.mid
            ) {
              return { ...message, ...action.payload };
            }
            return message;
          });
          updateIndexedDB(user.uuid, updateMsgUser.messages);
        }

        return updateMsgUser;
      });

      return {
        ...state,
        users: updatedUsers,
      };

    case DELIVERED_MESSAGE:
      var deliveredUsers = state.users.map((user) => {
        var updateDelUser = {
          ...user,
        };

        if (user.messages) {
          for (var upd = 0; upd < user.messages.length; upd++) {
            var tempDelMessage = null;
            var updateDelPayload = null;
            if (typeof user.messages[upd]._id === "undefined") {
              var chatMessage;
              if (Array.isArray(user.messages[upd].message)) {
                chatMessage = JSON.stringify(user.messages[upd].message[0]);
              } else {
                chatMessage = user.messages[upd].message;
              }
              if (
                chatMessage === action.payload.message ||
                chatMessage.includes(action.payload.message)
              ) {
                tempDelMessage = updateDelUser["messages"][upd];
                updateDelPayload = action.payload;
                updateDelUser["messages"][upd] = {
                  ...tempDelMessage,
                  ...updateDelPayload,
                };
              }
            } else {
              // UPDATE MESSAGE BY ANOTHER CONDITION
              if (user.messages[upd].mid === action.payload.mid) {
                tempDelMessage = updateDelUser["messages"][upd];
                updateDelPayload = action.payload;
                updateDelUser["messages"][upd] = {
                  ...tempDelMessage,
                  ...updateDelPayload,
                };
              }
            }
          }

          // Update IndexedDB
          updateIndexedDB(user.uuid, updateDelUser.messages);
        }
        return updateDelUser;
      });

      return {
        ...state,
        users: deliveredUsers,
      };

    case REMOVE_FROM_QUEUE:
      var queueUsers = state.users.map((user) => {
        var tempQueUser = {
          ...user,
        };

        if (user.messages) {
          for (var qm = 0; qm < user.messages.length; qm++) {
            if (user.messages[qm].mid === action.payload) {
              delete tempQueUser["messages"][qm].queueid;
            }
          }
        }
        return tempQueUser;
      });

      return {
        ...state,
        users: queueUsers,
      };

    case REMOVE_MESSAGE:
      const updatedUsersRemove = state.users.map((user) => {
        if (user.uuid === action.payload.chatroom) {
          const updatedMessages = user.messages.map((message) =>
            message._id === action.payload._id
              ? { ...message, status: 99 }
              : message
          );

          updateIndexedDB(user.uuid, updatedMessages);

          return {
            ...user,
            messages: updatedMessages,
          };
        }
        return user;
      });

      return {
        ...state,
        users: updatedUsersRemove,
      };

    case SET_ENTITIES:
      return {
        ...state,
        entities: action.payload,
      };

    case DELETE_ENTITY:
      return {
        ...state,
        entities: state.entities.filter((entity) => entity !== action.payload),
      };

    case SET_CONTACTS:
      // child.contact === localStorage.uid ? child.name : child.alias
      for (var ac = 0; ac < action.payload.length; ac++) {}
      var allContacts = action.payload.map((contact, index) => {
        var thisContact = {
          ...contact,
        };

        if (contact.contact === localStorage.uid) {
          thisContact.alias = contact.name;
          thisContact.name = contact.alias;
        }
        return thisContact;
      });
      return {
        ...state,
        contacts: allContacts,
      };

    case ADD_CONTACT:
      var contactList = state.contacts.slice();
      for (var a = 0; a < contactList.length; a++) {
        if (contactList[a].contact === action.payload.contact) {
          return {
            ...state,
          };
        } else {
        }
      }

      return {
        ...state,
        contacts: [...state.contacts, action.payload],
      };

    case BLOCK_CONTACT:
      var blockList = state.contacts.map((contact) => {
        var newblockList = {
          ...contact,
        };

        if (contact._id === action.payload) {
          newblockList.status = 99;
        }
        return newblockList;
      });

      return {
        ...state,
        contacts: blockList,
      };

    case SET_WIDGET_PROPERTIES:
      var widgetProperties = state.users.map((user) => {
        var widgetProps = {
          ...user,
        };

        if (user) {
          if (
            user.uuid === action.payload.uuid ||
            user.userid === action.payload.userid
          ) {
            widgetProps.widgetProperty = action.payload;
          }
        }

        return widgetProps;
      });

      return {
        ...state,
        users: widgetProperties,
      };

    case UPDATE_USER:
      var allUsers = state.users.map((user) => {
        var tempUpdateUser = {
          ...user,
        };

        if (
          user.uuid === action.payload.uuid ||
          user.userid === action.payload._id ||
          user.recipient === action.payload._id
        ) {
          tempUpdateUser.createdAt = action.payload.createdAt;
          tempUpdateUser.email = action.payload.email;
          tempUpdateUser.profilePicture = action.payload.image;
          tempUpdateUser.name = action.payload.name;
          tempUpdateUser.status = action.payload.status;
          tempUpdateUser.about = action.payload.about;
          tempUpdateUser.location = action.payload.location;
          tempUpdateUser.alias = action.payload.alias;
          tempUpdateUser.isBlocked = action.payload.isBlocked;
        }
        return tempUpdateUser;
      });

      return {
        ...state,
        users: allUsers,
      };

    case UPDATE_UNREAD:
      var allUserUnreads = state.users.map((user) => {
        var tempUnreadUser = {
          ...user,
        };

        if (user.uuid === action.payload.uuid) {
          if (action.payload.increment) {
            tempUnreadUser.unRead += 1;
          } else {
            tempUnreadUser.unRead = action.payload.unread;
          }
        }
        return tempUnreadUser;
      });

      return {
        ...state,
        users: allUserUnreads,
      };

    case UNBLOCK_CONTACT:
      var UnblockList = state.contacts.map((contact) => {
        var newUnblockList = {
          ...contact,
        };

        if (contact._id === action.payload) {
          newUnblockList.status = 1;
        }
        return newUnblockList;
      });
      return {
        ...state,
        contacts: UnblockList,
      };

    case DELETE_CONTACT:
      var deleteList = state.contacts.slice();
      for (var i = 0; i < deleteList.length; i++) {
        if (deleteList[i]._id === action.payload) {
          deleteList.splice(i, 1);
        }
      }
      return {
        ...state,
        contacts: deleteList,
      };

    case START_CHAT:
      const newChat = action.payload;
      let search = false;
      for (var key in state.users) {
        if (
          state.users[key].recipient === newChat.recipient ||
          state.users[key].userid === newChat.recipient
        ) {
          search = true;
          break;
        }
      }

      if (search) {
        return state;
      } else {
        return {
          ...state,
          users: [...state.users, newChat],
          active_user: state.users.length,
        };
      }

    case ADD_LOGGED_USER:
      const newUser = action.payload;
      return {
        ...state,
        users: [...state.users, newUser],
      };

    case CREATE_GROUP:
      const newGroup = action.payload;
      return {
        ...state,
        groups: [...state.groups, newGroup],
      };

    case SET_USER_ONLINE:
      var onlineUsers = state.users.map((user) => {
        var newonlineUsers = {
          ...user,
        };
        if (
          user.userid === action.payload ||
          user.recipient === action.payload
        ) {
          newonlineUsers.status = "online";
        }
        return newonlineUsers;
      });

      return {
        ...state,
        users: onlineUsers,
      };

    case SET_USER_AWAY:
      var awayUsers = state.users.map((user) => {
        var newAwayUsers = {
          ...user,
        };
        if (
          user.userid === action.payload ||
          user.recipient === action.payload
        ) {
          newAwayUsers.status = "away";
        }
        return newAwayUsers;
      });

      return {
        ...state,
        users: awayUsers,
      };

    case SET_USER_OFFLINE:
      var offlineUsers = state.users.map((user) => {
        var newofflineUsers = {
          ...user,
        };
        if (
          user.userid === action.payload ||
          user.recipient === action.payload
        ) {
          newofflineUsers.status = "offline";
        }
        return newofflineUsers;
      });

      return {
        ...state,
        users: offlineUsers,
      };

    case SET_NEW_MULTI_MESSAGE:
      var MMUserList = state.users.slice();
      let MultiMessageList = MMUserList.findIndex(
        (user) =>
          user.uuid === action.payload.chatroomId ||
          user.recipient === action.payload.userId
      );
      if (typeof MultiMessageList == "number" && MultiMessageList >= 0) {
        if (!MMUserList[MultiMessageList].messages) {
          MMUserList[MultiMessageList].messages = [];
        }
        MMUserList[MultiMessageList].messages.push(action.payload);
        updateIndexedDB(
          MMUserList[MultiMessageList].uuid,
          MMUserList[MultiMessageList].messages
        );
      } else {
        var newChatRoom = {
          uuid: action.payload.chatroomId,
          name: action.payload.name,
          userid: action.payload.userId,
          createdAt: action.payload.createdAt,
          // profilePicture: "Null",
          status: "online",
          messages: [action.payload],
        };
        MMUserList.push(newChatRoom);
        updateIndexedDB(newChatRoom.uuid, newChatRoom.messages);
      }

      return {
        ...state,
        users: MMUserList,
      };

    case SET_NEW_MESSAGE:
      var UserList = state.users.slice();
      let MessageList = UserList.findIndex(
        (user) =>
          user.userid === action.payload.userId ||
          user.userid === action.payload.recipient
      );

      if (typeof MessageList == "number" && MessageList >= 0) {
        if (!UserList[MessageList].messages) {
          UserList[MessageList].messages = [];
        }
        UserList[MessageList].messages.push(action.payload);
        updateIndexedDB(
          UserList[MessageList].uuid,
          UserList[MessageList].messages
        );
      } else {
        var newMessageRoom = {
          uuid: action.payload.chatroomId,
          name: action.payload.name,
          userid: action.payload.userId,
          createdAt: action.payload.createdAt,
          // profilePicture: "Null",
          status: "online",
          messages: [action.payload],
        };
        UserList.push(newMessageRoom);
        updateIndexedDB(newMessageRoom.uuid, newMessageRoom.messages);
      }

      return {
        ...state,
        users: UserList,
      };

    case READ_MESSAGE:
      const updatedUsersRead = state.users.map((user) => {
        if (user.uuid === action.payload.chatroomId) {
          const updatedMessages = user.messages.map((message) =>
            action.payload.messageIds.includes(message._id)
              ? { ...message, status: 2 }
              : message
          );

          updateIndexedDB(user.uuid, updatedMessages);

          return {
            ...user,
            messages: updatedMessages,
          };
        }
        return user;
      });

      return {
        ...state,
        users: updatedUsersRead,
      };

    case ROOM_OPENED:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.uuid === action.payload.chatroomId) {
            return {
              ...user,
              messages: user.messages.map((message) =>
                message.user === action.payload.sender && message.status === 1
                  ? { ...message, status: 2 }
                  : message
              ),
            };
          }
          return user;
        }),
      };

    default:
      return {
        ...state,
      };
  }
};

export default Chat;
